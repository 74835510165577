.accordion-item {
  border: none !important;
  background-color: transparent !important;

  .accordion-button {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    color: inherit;
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    &::after {
      filter: brightness(0) saturate(100%) invert(82%) sepia(6%) saturate(69%) hue-rotate(184deg)
        brightness(90%) contrast(90%);
    }
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;

    &::after {
      filter: brightness(0) saturate(100%) invert(82%) sepia(6%) saturate(69%) hue-rotate(184deg)
        brightness(90%) contrast(90%);
    }
  }

  .accordion-body {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}

.custom-checkbox {
  width: 16px;
  height: 16px;
  appearance: none;
  background-color: transparent;
  border: 2px solid var(--bs-secondary);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.custom-checkbox:checked::after {
  content: '✔';
  font-size: 12px;
  color: var(--bs-secondary);
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
