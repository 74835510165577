.article {
  overflow-wrap: break-word;
}

.article h6 {
  width: auto;
}

.article h1 {
  font-size: calc(1rem + 0.7cqw);
  width: auto;
}

.article h2 {
  font-size: calc(0.9rem + 0.7cqw) !important;
  width: auto;
}

.article h3 {
  font-size: calc(0.85rem + 0.7cqw) !important;
  width: auto;
}

.article h4 {
  font-size: calc(0.8rem + 0.7cqw) !important;
  width: auto;
}

.article h5 {
  font-size: calc(0.75rem + 0.7cqw) !important;
  width: auto;
}

.article h6 {
  font-size: calc(0.7rem + 0.7cqw) !important;
  width: auto;
}

.article img {
  width: 100%;
  height: auto;
}

.article a {
  text-decoration: none;
  color: var(--bs-primary-500);
}

.article tr {
  border-bottom: 1px solid gray;
}

.downloadPdfButton {
  text-decoration: none;
  font-weight: bold;
}

.downloadPdfButton:before {
  content: '📈 ';
}
