.mainNormalItem a {
  color: var(--bs-light);
}

.mainNormalItem:hover a {
  color: var(--bs-body-color);
  opacity: 70%;
}

.headlineViewer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  word-break: keep-all;

  li[data-belled='true'] {
    color: var(--bs-error);
  }
}
